import React from 'react'

import * as S from './TextInput.styles'

export type TextInputProps = {
  id: string
  name: string
  label?: string
  touched?: boolean
  error?: string
} & React.InputHTMLAttributes<HTMLInputElement>

const TextInput = ({ label, touched, error, className, ...inputProps }: TextInputProps) => (
  <S.TextInput className={className}>
    {label && <S.InputLabel htmlFor={inputProps.name}>{label}</S.InputLabel>}
    <S.Input {...inputProps} />
    {touched && error ? (
      <>
        <S.ErrorIcon />
        <S.InputError>{error}</S.InputError>
      </>
    ) : null}
  </S.TextInput>
)

export default TextInput
