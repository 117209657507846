import React from 'react'

import { useFormik } from 'formik'
import { Col, Row } from 'react-bootstrap'
import { useLocalStorage } from 'react-use'

import FormData from '../../types/FormData'
import * as S from './CashbackForm.styles'
import { validateNonAccHolderForm } from './validations'
import { maskCpf, maskNumeric, maskPhone } from '../../utils/form'
import SelectInput from '../SelectInput'
import Bank from '../../types/Bank'
import { lsKeyFormData } from '../../utils/constants'

const initialFormValues: FormData = {
  name: '',
  email: '',
  phone: '',
  cpf: '',
  bank: '',
  agency: '',
  account: '',
  termsAccepted: false,
  allowAdvertising: false,
}

export type CashbackFormProps = {
  onFormSubmit: (isAccHolder: boolean) => void
  banksList: Bank[]
  couponCode?: string
} & React.HTMLAttributes<HTMLDivElement>

const CashbackForm = ({ onFormSubmit, banksList, couponCode, ...rest }: CashbackFormProps) => {
  const [formData, setFormData] = useLocalStorage<FormData>(lsKeyFormData, initialFormValues)

  const formik = useFormik<FormData>({
    initialValues: formData as FormData,
    validate: validateNonAccHolderForm,
    onSubmit: handleSubmit,
  })

  function handleSubmit(values: FormData) {
    const trimmedValues: FormData = {
      ...values,
      email: values.email.trim(),
      name: values.name.trim(),
    }

    setFormData({
      ...trimmedValues,
    })

    onFormSubmit(false)
  }

  return (
    <S.CashbackForm {...rest}>
      <S.Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} sm={6} lg={{ span: 5, offset: 1 }}>
            <S.TextInput
              type="text"
              label="Nome"
              id="name"
              name="name"
              placeholder="Nome completo"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              touched={formik.touched.name}
              error={formik.errors.name}
            />

            <S.TextInput
              type="text"
              label="CPF"
              id="cpf"
              name="cpf"
              placeholder="Digite seu CPF"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                formik.setFieldValue('cpf', maskCpf(event.target.value))
              }
              onBlur={formik.handleBlur}
              value={formik.values.cpf}
              touched={formik.touched.cpf}
              error={formik.errors.cpf}
            />

            <S.TextInput
              type="text"
              label="Telefone"
              id="phone"
              name="phone"
              placeholder="(00) 00000-0000"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                formik.setFieldValue('phone', maskPhone(event.target.value))
              }
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              touched={formik.touched.phone}
              error={formik.errors.phone}
            />

            <S.TextInput
              type="text"
              label="E-mail"
              id="email"
              name="email"
              placeholder="Digite seu melhor e-mail"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              touched={formik.touched.email}
              error={formik.errors.email}
            />
          </Col>

          <Col xs={12} sm={6} lg={5}>
            <SelectInput
              label="Banco"
              id="bank"
              name="bank"
              value={formik.values.bank}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.bank}
              error={formik.errors.bank}
            >
              <option value="">Selecione</option>
              {banksList.map((bank) => (
                <option key={bank.code} value={bank.code}>
                  {bank.bankName}
                </option>
              ))}
            </SelectInput>

            <S.TextInput
              type="text"
              label="Agência, sem dígito"
              id="agency"
              name="agency"
              placeholder="Digite apenas números"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                formik.setFieldValue('agency', maskNumeric(event.target.value))
              }
              onBlur={formik.handleBlur}
              value={formik.values.agency}
              touched={formik.touched.agency}
              error={formik.errors.agency}
            />

            <S.TextInput
              type="text"
              label="Conta corrente, com dígito"
              id="account"
              name="account"
              placeholder="Digite apenas números"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                formik.setFieldValue('account', maskNumeric(event.target.value))
              }
              onBlur={formik.handleBlur}
              value={formik.values.account}
              touched={formik.touched.account}
              error={formik.errors.account}
            />
          </Col>

          <Col xs={12} sm={{ span: 10, offset: 1 }}>
            <S.Checkbox
              name="termsAccepted"
              id="termsAccepted"
              checked={formik.values.termsAccepted}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <span>
                Li e concordo com a&nbsp;
                <a
                  href="https://galonaveia.atletico.com.br/assets/archives/manual-dados-pessoais.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <b>Política de Privacidade</b>
                </a>
                &nbsp;e com os&nbsp;
                <a
                  href="https://galonaveia.atletico.com.br/assets/archives/termos-shopping-da-massa.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <b>Termos e Condições</b>
                </a>
                .
              </span>
            </S.Checkbox>

            <S.Checkbox
              name="allowAdvertising"
              id="allowAdvertising"
              checked={formik.values.allowAdvertising}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <span>Quero receber ofertas e publicidade do Atlético.</span>
            </S.Checkbox>

            {couponCode && <S.CouponDetails couponCode={couponCode} />}
          </Col>

          <Col xs={12}>
            <S.Button
              type="submit"
              disabled={!formik.values.termsAccepted || formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              <b>{couponCode ? `Copiar e ir` : 'Ir'} para a loja</b>
            </S.Button>
          </Col>
        </Row>
      </S.Form>
    </S.CashbackForm>
  )
}

export default CashbackForm
