/**
 * Get the last string after '/'
 *
 * @param path - path to extract the last string
 * @returns - a single string
 *
 * @example
 *
 * getStoreSlugFromPath('amazon') // ''
 * getStoreSlugFromPath('lojas/aliexpress') // aliexpress
 * getStoreSlugFromPath('base/home/store/amazon') // ''
 * getStoreSlugFromPath('lojas/amazon/') // amazon
 */
export const getStoreSlugFromPath = (path: string): string => {
  const match = path.match(/parceiro\/([a-z0-9-]+)/i)
  return match ? match[1] : ''
}

/**
 * Returns only AlphaNumericals
 *
 * @param text - text to be filtered
 * @returns - a single string
 *
 * @example
 *
 * getAlphaNumerical('123-123') // 123123
 * getStoreSlugFromPath('abc123.5') // abc1235
 */
export const getAlphaNumerical = (text: string): string => text.replace(/[^a-z0-9]/gi, '')
