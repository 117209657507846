import styled from 'styled-components'

import {
  AlertBox as AlertBoxComponent,
  Shimmer,
  StoreDetailsHeader as StoreDetailsHeaderComponent,
  StoreDetailsHeaderShimmer as StoreDetailsHeaderShimmerComponent,
  StoreImportantInfo as StoreImportantInfoComponent,
  PromotionsListExtended as PromotionsListExtendedComponent,
  PromotionsListCompact as PromotionsListCompactComponent,
  PageError as PageErrorComponent,
} from '@interco/affiliate-shopping-ui'
import { Button } from '@inter/inter-ui/components/Button'

import { mediaQuery } from '../../../assets/styles/variables'
import HowItWorksComponent from '../../HowItWorksCard'

export const AlertBox = styled(AlertBoxComponent)`
  margin-bottom: 2rem;
`

export const StoreDetailsHeader = styled(StoreDetailsHeaderComponent)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`

export const StoreImportantInfo = styled(StoreImportantInfoComponent)`
  margin-top: 2rem;

  @media ${mediaQuery.minLG} {
    margin-top: 0;
  }
`

export const PromotionTermsTitle = styled.h3`
  font-size: 1rem;
  color: var(--gray500);
  font-weight: 600;
`

export const PromotionTermsText = styled.div`
  margin: 0.5rem 0 2rem;
`

// How to Earn Cashback

export const Title = styled.h2`
  font-family: 'Sora';
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

export const Steps = styled.ol`
  margin: 1rem 0 2rem;
  list-style: none;
`

export const Step = styled.li`
  display: flex;
  margin-bottom: 1rem;
`

export const StepNumber = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
  margin: -3px 0.5rem 3px 0;
`

export const ClosePromotionTermsButton = styled(Button)`
  width: 100% !important;
`

export const PromotionsListExtended = styled(PromotionsListExtendedComponent)`
  scroll-margin-top: 5rem;
`

export const PromotionsListCompact = styled(PromotionsListCompactComponent)`
  scroll-margin-top: 5rem;
`

export const HowItWorks = styled(HowItWorksComponent)`
  margin: 4rem 0 3rem;

  p {
    font-size: 1rem;
  }
`

// Shimmer styles

export const StoreDetailsHeaderShimmer = styled(StoreDetailsHeaderShimmerComponent)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`

export const AlertBoxShimmer = styled(Shimmer)`
  margin-bottom: 2rem;
`

export const PageError = styled(PageErrorComponent)`
  margin: 3rem auto;
  align-items: center;

  @media ${mediaQuery.minMD} {
    margin: 12rem auto 3rem;
  }
`

export const PageErrorImg = styled.img`
  width: 10rem;
  margin-bottom: 1rem;

  @media ${mediaQuery.minMD} {
    width: 15.63rem;
    margin-bottom: 0;
  }
`
