import { Col } from 'react-bootstrap'
import styled from 'styled-components'

export const Divider = styled.div`
  margin-bottom: 1.5rem;
  border: 1px dashed var(--gray200);
`

export const CouponDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`

export const DetailText = styled.p`
  color: var(--gray400);
`

export const Coupon = styled(Col)`
  text-align: center;
  width: 100%;
  padding: 0.875rem 0;
  border: 1px dashed var(--gray300);
  border-radius: 4px;
  margin-bottom: 1rem;
  max-width: 290px;
  color: var(--gray500);
`
