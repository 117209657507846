import React from 'react'

import { BaseModal } from '@interco/affiliate-shopping-ui'

import * as S from './ActivateCashbackModal.styles'
import StoreDetailsRes from '../../types/StoreDetailsRes'
import useAsync from '../../hooks/useAsync'
import Bank from '../../types/Bank'
import { getBanks } from '../../api'
import CashbackForm from '../CashbackForm'

interface ActivateCashbackModalProps {
  show: boolean
  store: StoreDetailsRes
  onClose: () => void
  onFormSubmit: (isAccHolder: boolean) => void
  couponCode?: string
}

const ActivateCashbackModal = ({
  show,
  store,
  onClose,
  onFormSubmit,
  couponCode,
}: ActivateCashbackModalProps) => {
  const banksReq = useAsync<Bank[]>()

  React.useEffect(() => {
    banksReq.run(getBanks())
  }, [])

  return (
    <BaseModal show={show} onClose={onClose} size="lg">
      <S.Header>
        <img
          src={store.imageUrl}
          alt="store logo"
          width={132}
          height="auto"
          data-testid="store-logo"
        />
      </S.Header>

      <S.FormTitle>
        Torcedor do Galo, informe seus dados para ganhar{' '}
        {store.storeCashbacks.fullCashback?.toLowerCase()}
      </S.FormTitle>
      <S.FormDescription>
        Depositaremos o cashback na conta que você informar. Pagando com seu cartão de crédito Meu
        Galo BMG, você ainda tem cashback na fatura! Consulte as regras. Pode ficar tranquilo, aqui
        é um ambiente seguro.
      </S.FormDescription>
      <CashbackForm
        banksList={banksReq.data || []}
        onFormSubmit={onFormSubmit}
        couponCode={couponCode}
        data-testid="non-acc-holder-form"
      />
    </BaseModal>
  )
}

export default ActivateCashbackModal
