import React from 'react'

import * as S from './SelectInput.styles'

export type SelectInputProps = {
  id: string
  name: string
  label?: string
  value?: string
  options?: string[]
  touched?: boolean
  error?: string
  children: React.ReactNode
} & React.SelectHTMLAttributes<HTMLSelectElement>

const SelectInput = ({
  label,
  touched,
  error,
  className,
  children,
  ...inputProps
}: SelectInputProps) => (
  <S.SelectInput className={className}>
    {label && <S.InputLabel htmlFor={inputProps.name}>{label}</S.InputLabel>}
    <S.Select {...inputProps} isEmpty={!inputProps.value} isError={!!error}>
      {children}
    </S.Select>
    {touched && error ? (
      <>
        <S.ErrorIcon />
        <S.InputError>{error}</S.InputError>
      </>
    ) : null}
  </S.SelectInput>
)
export default SelectInput
