import styled from 'styled-components'

import { Button as ButtonComponent } from '@inter/inter-ui/components/Button'

export const Title = styled.h2`
  font-family: 'Sora';
  font-size: 1.5rem;
  margin: 1rem 0;
`

export const Steps = styled.ol`
  margin: 1rem 0 2rem;
  list-style: none;
`

export const Step = styled.li`
  display: flex;
  margin-bottom: 1rem;
`

export const StepNumber = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
  margin: -3px 0.5rem 3px 0;
`

export const Button = styled(ButtonComponent)`
  width: 100% !important;
`
