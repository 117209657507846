import React from 'react'

import * as S from './CouponDetails.styles'

type CouponDetailsProps = {
  couponCode: string
} & React.HTMLAttributes<HTMLDivElement>

const CouponDetails = ({ couponCode, ...rest }: CouponDetailsProps) => (
  <div data-testid="coupon-details" {...rest}>
    <S.Divider />
    <S.CouponDetails>
      <S.DetailText>Copie o cupom, vá para a loja e cole-o no carrinho:</S.DetailText>
      <S.Coupon xs={12} data-testid="coupon">
        <b>{couponCode}</b>
      </S.Coupon>
    </S.CouponDetails>
  </div>
)

export default CouponDetails
