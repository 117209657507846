import styled from 'styled-components'

import IconOff from './icon-off.svg'
import IconOn from './icon-on.svg'

export const Checkbox = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: var(--gray400);
`

export const CheckboxInput = styled.input`
  &[type='checkbox'] {
    display: inline-block;
    flex-shrink: 0;
    font-size: 0.875rem;
    appearance: none;
    width: 1.125rem;
    height: 1.125rem;
    align-self: center;
    margin-right: 0.75rem;
    background-image: url(${IconOff});
    background-size: contain;
    background-repeat: no-repeat;
  }

  &[type='checkbox']:hover {
    cursor: pointer;
  }

  &[type='checkbox']:checked {
    background-image: url(${IconOn});
  }
`
