/**
 * Format a ISO date string to pt-BR format
 *
 * @param value ISO date string
 * @returns formated value based on the selected language
 *
 * @example
 *
 * formatDate('2020-10-02') // 02/10/2020
 */
export function formatDate(value: string): string {
  let date

  try {
    const [year, month, day] = value.split('-').map((val) => parseInt(val, 10))

    // Decrement the month since JS months index from zero
    date = new Date(year, month - 1, day)
  } catch (err) {
    console.error('Could not parse provided date string', err) // eslint-disable-line no-console
    return ''
  }

  const intl = new Intl.DateTimeFormat('pt-BR')
  return intl.format(date)
}
