// Validations
// -----------

export const validateName = (str: string): boolean => /^[A-Zà-ú]+(\s[A-Zà-ú']+)+$/iu.test(str)

export const validatePhone = (str: string): boolean => /^\(\d{2}\) \d{5}-\d{4}$/.test(str)

export const validateEmail = (str: string): boolean =>
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
    str,
  )

// Input masks
// -----------

export const maskCpf = (text: string): string => {
  let cleanCpf = text.replace(/\D/g, '').substring(0, 11)

  if (cleanCpf.length > 9) {
    cleanCpf = cleanCpf.replace(/^(\d{3})(\d{3})(\d{3})(\d+)/, '$1.$2.$3-$4')
  } else if (cleanCpf.length > 6) {
    cleanCpf = cleanCpf.replace(/^(\d{3})(\d{3})(\d+)/, '$1.$2.$3')
  } else if (cleanCpf.length > 3) {
    cleanCpf = cleanCpf.replace(/^(\d{3})(\d+)/, '$1.$2')
  }

  return cleanCpf
}

export const maskPhone = (str: string): string => {
  const cleanNumber = str.replace(/\D/g, '').substr(0, 11)

  if (cleanNumber.length < 3) {
    return cleanNumber
  }

  if (cleanNumber.length < 7) {
    return cleanNumber.replace(/^(\d{2})(\d+)/, '($1) $2')
  }

  if (cleanNumber.length < 11) {
    return cleanNumber.replace(/^(\d{2})(\d{4})(\d+)/, '($1) $2-$3')
  }

  return cleanNumber.replace(/^(\d{2})(\d{5})(\d*)/, '($1) $2-$3')
}

export const maskNumeric = (value: string): string => value.replace(/[^\d]/g, '')
