import styled from 'styled-components'

import { Button as ButtonComponent } from '@inter/inter-ui/components/Button'

import TextInputComponent from '../TextInput'
import CouponDetailsComponent from '../CouponDetails'
import CheckboxComponent from '../Checkbox'

export const CashbackForm = styled.div``

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const TextInput = styled(TextInputComponent)`
  margin: 1rem 0;
`

export const CouponDetails = styled(CouponDetailsComponent)`
  margin-top: 3rem;
`

export const Checkbox = styled(CheckboxComponent)`
  margin-bottom: 2rem;

  &:first-child {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
`

export const Button = styled(ButtonComponent)`
  max-width: 290px;
  width: 100% !important;
  margin: 0 auto;
  display: flex !important;
`
