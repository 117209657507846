import { cpf } from 'cpf-cnpj-validator'

import FormData from '../../types/FormData'
import { validateEmail, validateName, validatePhone } from '../../utils/form'

export const validateNonAccHolderForm = (values: FormData) => {
  const errors: Partial<FormData> = {}

  // Name input
  if (!validateName(values.name.trim())) {
    errors.name = 'Digite o seu nome completo'
  }

  // CPF input
  if (!cpf.isValid(values.cpf.trim())) {
    errors.cpf = 'Digite um CPF válido'
  }

  // Phone input
  if (!validatePhone(values.phone.trim())) {
    errors.phone = 'Informe um telefone válido'
  }

  // Email input
  if (!validateEmail(values.email.trim())) {
    errors.email = 'Digite um e-mail válido'
  }

  // Bank input
  if (!values.bank) {
    errors.bank = 'Selecione seu banco'
  }

  // Agency input
  if (!values.agency) {
    errors.agency = 'Digite o número da agência'
  }

  // Account input
  if (!(values.account.length > 3)) {
    errors.account = 'Digite o número da conta'
  }

  return errors
}
