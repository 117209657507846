import * as React from 'react'

import { Link, navigate, PageProps } from 'gatsby'
import { debounce, isEmpty, startCase } from 'lodash'
import { Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { useLocalStorage } from 'react-use'

import {
  BaseModal,
  BottomSheet,
  Breadcrumbs,
  FloatingButton,
  PromotionsItemExtended,
  PromotionsItemCompact,
  PromotionsListExtendedShimmer,
  PromotionsListCompactShimmer,
  Shimmer,
  StoreDetailsAbout,
  StoreDetailsAboutShimmer,
  StoreImportantInfoShimmer,
} from '@interco/affiliate-shopping-ui'

import { getStoreDetails } from '../../../api'
import { mediaQuery } from '../../../assets/styles/variables'
import useAsync from '../../../hooks/useAsync'
import PromotionsItem from '../../../types/PromotionsItem'
import SelectedStoreInfo from '../../../types/SelectedStoreInfo'
import StoreDetailsRes from '../../../types/StoreDetailsRes'
import { lsKeyStoreInfoData } from '../../../utils/constants'
import { formatDate } from '../../../utils/date'
import { getStoreSlugFromPath } from '../../../utils/misc'
import ActivateCashbackModal from '../../ActivateCashbackModal'
import HowToEarnCashback from '../../HowToEarnCashback'
import Layout from '../../Layout'
import Seo from '../../Seo'
import * as S from './StoreDetails.styles'

const SCROLL_Y_TO_SHOW_FLOATING_BUTTON = 250

const initialSelectedStoreInfoValue: SelectedStoreInfo = {
  storeId: '',
  storeLink: '',
  storeName: '',
}

const StoreDetails = ({ location }: PageProps) => {
  const storeDetailsReq = useAsync<StoreDetailsRes>()
  const storeSlug = getStoreSlugFromPath(location.pathname)

  // Media queries variables
  const minMD = useMediaQuery({ query: mediaQuery.minMD })
  const minSM = useMediaQuery({ query: mediaQuery.minSM })
  const minLG = useMediaQuery({ query: mediaQuery.minLG })

  // General page states
  const [showFloatingButton, setShowFloatingButton] = React.useState(false)
  const [selectedPromotion, setSelectedPromotion] = React.useState<PromotionsItem>()

  // Modal windows states
  const [howToEarnDesktopOpen, setHowToEarnDesktopOpen] = React.useState(false)
  const [howToEarnModalOpen, setHowToEarnMobileOpen] = React.useState(false)
  const [activateCashbackOpen, setActivateCashbackOpen] = React.useState(false)
  const [promotionsTermsBottomSheetOpened, setPromotionsTermsBottomSheetOpened] =
    React.useState(false)

  // Local storage states
  const [, setSelectedStoreInfo] = useLocalStorage<SelectedStoreInfo>(
    lsKeyStoreInfoData,
    initialSelectedStoreInfoValue,
  )

  const defaultStorePromotion = {
    id: 'default',
    title: storeDetailsReq.data?.storeCashbacks.fullCashback,
    subtitle: storeDetailsReq.data?.showPartialCashback
      ? `${storeDetailsReq.data?.storeCashbacks.partialCashback} para não correntistas`
      : '',
    description: 'Aproveite o Cashback para economizar em toda a loja.',
    url: storeDetailsReq.data?.storeUrl,
  } as PromotionsItem

  // Computed props
  const getPageTitle = () => {
    if (storeDetailsReq.isSuccess || storeDetailsReq.isError) {
      return storeDetailsReq.data
        ? `Ganhe cashback na ${storeDetailsReq.data.name}`
        : 'Loja não econtrada'
    }
    return `Ganhe cashback na ${startCase(storeSlug.replaceAll('-', ' '))}`
  }

  // Utility functions
  // -----------------

  const goToStoreRedirect = () => {
    if (storeDetailsReq.data) {
      setSelectedStoreInfo({
        storeLink: selectedPromotion?.url || '',
        storeId: storeDetailsReq.data.id,
        storeName: storeDetailsReq.data.name,
      })

      window.open(`/ativando-cashback`, '_blank')
      setActivateCashbackOpen(false)
    }
  }

  // Event Handlers
  // --------------

  const handleFlatCashbackButtonCLick = () => {
    setSelectedPromotion(undefined)
    setActivateCashbackOpen(true)
  }

  const handlePromotionButtonClick = (promotion: PromotionsItem) => {
    setSelectedPromotion(promotion)
    setActivateCashbackOpen(true)
  }

  const handleFormSubmit = async () => {
    if (selectedPromotion?.code) {
      await navigator.clipboard.writeText(selectedPromotion.code)
    }

    goToStoreRedirect()
  }

  const handleHowToEarnButtonClick = () => {
    if (!minSM) {
      setHowToEarnMobileOpen(true)
    } else {
      setHowToEarnDesktopOpen(true)
    }
  }

  const handleTermsButtonClick = (promotion: PromotionsItem) => {
    setSelectedPromotion(promotion)
    setPromotionsTermsBottomSheetOpened(true)
  }

  // useEffect hooks
  // ---------------

  React.useEffect(() => {
    if (storeSlug) {
      storeDetailsReq.run(getStoreDetails(storeSlug)).catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    } else {
      navigate(`/parceiros`)
    }
  }, [storeSlug])

  React.useEffect(() => {
    const onScroll = debounce(() => {
      const currentScrollY = window.scrollY

      if (minSM || SCROLL_Y_TO_SHOW_FLOATING_BUTTON > currentScrollY) {
        setShowFloatingButton(false)
      }

      if (
        storeDetailsReq.isSuccess &&
        !minSM &&
        currentScrollY > SCROLL_Y_TO_SHOW_FLOATING_BUTTON
      ) {
        setShowFloatingButton(true)
      }
    }, 200)

    window.addEventListener('scroll', onScroll, { passive: true })

    return () => window.removeEventListener('scroll', onScroll)
  }, [storeDetailsReq.isSuccess, minSM])

  React.useEffect(() => {
    if (!isEmpty(storeDetailsReq.data) && location.hash) {
      const element = document.getElementById('promotions')
      element?.scrollIntoView()
    }
  }, [location.hash, storeDetailsReq.data])

  const getPromotionsItemProps = (promotion: PromotionsItem) => ({
    key: promotion.id,
    promotion,
    onActivateButtonClick: () => {
      handlePromotionButtonClick(promotion)
    },
    onTermsButtonClick: () => {
      handleTermsButtonClick(promotion)
    },
    expirationDateText: promotion.validUntil
      ? `Válido até ${formatDate(promotion.validUntil)}`
      : '',
    termsButtonText: 'Ver condicões',
    revealButtonText: 'Ver cupom',
    shopNowButtonText: 'Comprar agora',
    codeLabelText: 'Código',
  })

  // Render
  // ------

  return (
    <>
      <Layout>
        <Seo
          title={`${getPageTitle()} | Inter Shop`}
          description={
            storeDetailsReq.isSuccess && storeDetailsReq.data
              ? `Receba cashback na ${storeDetailsReq.data.name} nas suas compras no Inter Shop. Aproveite as ofertas + dinheiro de volta!`
              : 'Receba cashback nas suas compras no Inter Shop. Aproveite as ofertas + dinheiro de volta!'
          }
          canonical={`/parceiro/${storeSlug}`}
        />

        <Container>
          {/* Store Details */}
          {storeDetailsReq.isSuccess && storeDetailsReq.data && (
            <>
              {/* BreadCrumb */}
              <Row>
                <Col xs={12}>
                  <Breadcrumbs>
                    <Breadcrumbs.Item label="Shopping" url="/" as={Link} />
                    <Breadcrumbs.Item label="Parceiros" url="/parceiros" as={Link} />
                    <Breadcrumbs.Item label={storeDetailsReq.data?.name || ''} />
                  </Breadcrumbs>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <S.StoreDetailsHeader
                    bannerUrl={storeDetailsReq.data?.bannerUrl || ''}
                    title={`Ganhe ${storeDetailsReq.data?.storeCashbacks.fullCashback
                      .toLocaleLowerCase()
                      .replace('r$', 'R$')} na ${storeDetailsReq.data?.name}`}
                    subtitle={
                      storeDetailsReq.data?.storeCashbacks.partialCashback &&
                      `${storeDetailsReq.data?.storeCashbacks.partialCashback
                        .toLowerCase()
                        .replace('r$', 'R$')} para não correntistas`
                    }
                    firstButtonLabel="Comprar agora"
                    secondButtonLabel="Como ganhar cashback"
                    onClickFirstButton={handleFlatCashbackButtonCLick}
                    onClickSecondButton={handleHowToEarnButtonClick}
                  />
                </Col>

                {minLG && (
                  <Col lg={3}>
                    <StoreDetailsAbout
                      logo={storeDetailsReq.data.imageUrl}
                      logoAlt={storeDetailsReq.data.name}
                      description={storeDetailsReq.data.summary || ''}
                      seeMoreText="Ver mais"
                      seeLessText="Ver menos"
                      lengthToTruncate={100}
                    />
                    <S.StoreImportantInfo
                      title="Informações importantes"
                      conditions="Mostrar Termos e Condições"
                      termsLink="https://galonaveia.atletico.com.br/assets/archives/termos-shopping-da-massa.pdf"
                      importantInfos={storeDetailsReq.data.importantInfos}
                    />
                  </Col>
                )}

                <Col lg={9}>
                  {storeDetailsReq.data?.additionalInfos && (
                    <S.AlertBox title="Regras do cashback">
                      {storeDetailsReq.data.additionalInfos}
                    </S.AlertBox>
                  )}

                  {!minLG && (
                    <>
                      <StoreDetailsAbout
                        logo={storeDetailsReq.data.imageUrl}
                        logoAlt={storeDetailsReq.data.name}
                        description={storeDetailsReq.data.summary || ''}
                        seeMoreText="Ver mais"
                        seeLessText="Ver menos"
                        horizontal
                        lengthToTruncate={!minSM ? 100 : 350}
                        logoColProps={{ xs: 4, md: 3 }}
                      />
                      <S.StoreImportantInfo
                        title="Informações importantes"
                        conditions="Mostrar Termos e Condições"
                        termsLink="https://galonaveia.atletico.com.br/assets/archives/termos-shopping-da-massa.pdf"
                        importantInfos={storeDetailsReq.data.importantInfos}
                        collapseMode
                      />
                    </>
                  )}

                  <S.HowItWorks />

                  {minMD ? (
                    <S.PromotionsListExtended promotionsTitleText="ofertas" id="promotions">
                      {[...storeDetailsReq.data.couponsAndPromotions, defaultStorePromotion].map(
                        (promotion) => (
                          <PromotionsItemExtended {...getPromotionsItemProps(promotion)} />
                        ),
                      )}
                    </S.PromotionsListExtended>
                  ) : (
                    <S.PromotionsListCompact promotionsTitleText="ofertas" id="promotions">
                      {[...storeDetailsReq.data.couponsAndPromotions, defaultStorePromotion].map(
                        (promotion) => (
                          <PromotionsItemCompact {...getPromotionsItemProps(promotion)} />
                        ),
                      )}
                    </S.PromotionsListCompact>
                  )}
                </Col>
              </Row>

              {/* How to earn cashback bottomsheet */}
              <BottomSheet
                portalElementId="modal-area"
                open={howToEarnModalOpen}
                onClose={() => setHowToEarnMobileOpen(false)}
              >
                <HowToEarnCashback onButtonClick={() => setHowToEarnMobileOpen(false)} />
              </BottomSheet>

              {/* How to earn cashback Modal */}
              <BaseModal
                size="xs"
                show={howToEarnDesktopOpen}
                onClose={() => setHowToEarnDesktopOpen(false)}
              >
                <HowToEarnCashback onButtonClick={() => setHowToEarnDesktopOpen(false)} />
              </BaseModal>

              {/* Activate cashback Modal */}
              <ActivateCashbackModal
                show={activateCashbackOpen}
                onFormSubmit={handleFormSubmit}
                onClose={() => setActivateCashbackOpen(false)}
                store={storeDetailsReq.data}
                couponCode={selectedPromotion?.code}
              />

              {/* Cashback Terms Modal */}
              <BottomSheet
                portalElementId="modal-area"
                open={promotionsTermsBottomSheetOpened}
                onClose={() => {
                  setPromotionsTermsBottomSheetOpened(false)
                  setSelectedPromotion(undefined)
                }}
              >
                {selectedPromotion && (
                  <>
                    <S.PromotionTermsTitle>Condições</S.PromotionTermsTitle>
                    <S.PromotionTermsText>{selectedPromotion.terms}</S.PromotionTermsText>
                    <S.ClosePromotionTermsButton
                      onClick={() => setPromotionsTermsBottomSheetOpened(false)}
                    >
                      <b>Entendi</b>
                    </S.ClosePromotionTermsButton>
                  </>
                )}
              </BottomSheet>
            </>
          )}

          {/* Shimmers */}
          {storeDetailsReq.isLoading && (
            <Row>
              <Col xs={12}>
                <Shimmer width="14rem" height="1rem" borderRadius="0.25rem" />
                <S.StoreDetailsHeaderShimmer />
              </Col>
              <Col lg={3}>
                <StoreDetailsAboutShimmer horizontal={!minLG} />
                <StoreImportantInfoShimmer collapseMode={!minLG} />
              </Col>
              <Col lg={9}>
                <S.AlertBoxShimmer height="70px" />
                {minMD ? <PromotionsListExtendedShimmer /> : <PromotionsListCompactShimmer />}
              </Col>
            </Row>
          )}

          {storeDetailsReq.isError && (
            <S.PageError
              title="Ops... loja não encontrada!"
              buttonLabel="Ver todas as lojas"
              buttonUrl="/parceiros"
              linkTag={Link}
            />
          )}
        </Container>
      </Layout>

      {/* Activate cashback floating button */}
      {showFloatingButton && (
        <FloatingButton onClick={handleFlatCashbackButtonCLick}>
          <b>Comprar agora</b>
        </FloatingButton>
      )}
    </>
  )
}

export default StoreDetails
