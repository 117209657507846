import * as React from 'react'

import * as S from './Checkbox.styles'

type CheckboxProps = {
  id: string
  name: string
  checked?: boolean
  children: React.ReactNode
} & React.HTMLAttributes<HTMLInputElement>

const Checkbox = ({ children, className, ...inputProps }: CheckboxProps) => (
  <S.Checkbox className={className} htmlFor={inputProps.name}>
    <S.CheckboxInput {...inputProps} type="checkbox" />
    {children}
  </S.Checkbox>
)

export default Checkbox
