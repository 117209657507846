import styled from 'styled-components'

import { mediaQuery } from '../../assets/styles/variables'

export const ActivateCashbackModal = styled.div``

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${mediaQuery.minMD}) {
    flex-direction: row;
    justify-content: center;
  }
`

export const FormTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 2rem 0 0.75rem;
  text-align: center;
`

export const FormDescription = styled.p`
  text-align: center;
  max-width: 36rem;
  margin: 0 auto;
`
