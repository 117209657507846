import styled from 'styled-components'

import ErrorIconSvg from '../../assets/icons/error-icon.svg'
import dropdownArrow from '../../assets/images/svgs/dropdown-arrow.svg'

export const SelectInput = styled.div`
  position: relative;
  margin: 1rem 0;
`

export const InputLabel = styled.label`
  display: block;
  color: var(--gray400);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`

interface SelectProps {
  isEmpty?: boolean
  isError?: boolean
}
export const Select = styled.select<SelectProps>`
  padding: 0.875rem 1rem;
  border: none;
  background: var(--gray100);
  font-size: 0.875rem;
  font-weight: 600;
  width: 100%;
  border-radius: 0.5rem;
  appearance: none;
  color: ${(props) => (props.isEmpty ? 'var(--gray300)' : 'var(--gray500)')};
  ${(props) =>
    !props.isError
      ? `
        background-image: url(${dropdownArrow});
        background-repeat: no-repeat;
        background-position: 94%;
        background-size: 0.75rem;
      `
      : ''}
`

export const ErrorIcon = styled(ErrorIconSvg)`
  position: absolute;
  bottom: 2.35rem;
  right: 0.625rem;
  color: var(--error500);
  width: 1.125rem;
  height: 1.125rem;
`

export const InputError = styled.div`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--error500);
  font-weight: 600;
  text-align: right;
  margin-top: 0.25rem;
`
