import * as React from 'react'

import * as S from './HowToEarnCashback.styles'

interface HowToEarnCashbackProps {
  onButtonClick: () => void
}

const HowToEarnCashback = ({ onButtonClick }: HowToEarnCashbackProps) => (
  <>
    <>
      <S.Title>Como ganhar cashback</S.Title>
      <S.Steps>
        <S.Step>
          <S.StepNumber>1.</S.StepNumber>
          <div>
            Clique em <strong>&ldquo;Ir para a loja&ldquo;</strong>
          </div>
        </S.Step>

        <S.Step>
          <S.StepNumber>2.</S.StepNumber>
          <div>
            <strong>Finalize sua compra</strong> no site da loja
          </div>
        </S.Step>

        <S.Step>
          <S.StepNumber>3.</S.StepNumber>
          <div>
            <strong>Receba o cashback</strong> entre 45 e 120 dias, direto na sua conta.
          </div>
        </S.Step>
      </S.Steps>
    </>

    <S.Button onClick={onButtonClick}>
      <b>Entendi</b>
    </S.Button>
  </>
)

export default HowToEarnCashback
